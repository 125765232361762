<template>
  <div>

    <base-header class="pb-6 pb-8 pt-5 pt-md-8 bg-gradient-orange app-view dashboard">
      <!-- Card stats -->
      <b-row v-if="!isSupplier">
        <b-col xl="3" md="12">
          <stats-card data-mh
                      title="Total Loans"
                      type="gradient-red"
                      :sub-title="toString(this.stats.newLoansAllTime)"
                      icon="ni ni-active-40"
                      class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">{{ toString(this.stats.newLoansYearToDate) }}</span>
              <span class="text-nowrap">Year to Date</span><br/>
              <span class="text-success mr-2">{{ toString(this.stats.newLoansThisMonth) }}</span>
              <span class="text-nowrap">This month</span><br/>
              <span class="text-success mr-2">{{ toString(this.stats.newLoansLastMonth) }}</span>
              <span class="text-nowrap">Last month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="12">
          <stats-card data-mh
                      title="Total Loans value"
                      type="gradient-orange"
                      :sub-title="formatCurrency(this.stats.LoansTotalAllTime == null ? 0 : this.stats.LoansTotalAllTime)"
                      icon="ni ni-chart-pie-35"
                      class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">{{ formatCurrency(this.stats.LoansTotalYearToDate == null ? 0 : this.stats.LoansTotalYearToDate)}}</span>
              <span class="text-nowrap">Year to Date</span><br/>
              <span class="text-success mr-2">{{ formatCurrency(this.stats.LoansTotalThisMonth == null ? 0 : this.stats.LoansTotalThisMonth)}}</span>
              <span class="text-nowrap">This month</span><br/>
              <span class="text-success mr-2">{{ formatCurrency(this.stats.LoansTotalLastMonth == null ? 0 : this.stats.LoansTotalLastMonth)}}</span>
              <span class="text-nowrap">Last month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="12">
          <stats-card data-mh
                      title="New Clients"
                      type="gradient-green"
                      :sub-title="toString(this.stats.NewClientsAllTime)"
                      icon="ni ni-chart-bar-32"
                      class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">{{ toString(this.stats.NewClientsYearToDate) }}</span>
              <span class="text-nowrap">Year to Date</span><br/>
              <span class="text-success mr-2">{{ toString(this.stats.NewClientsThisMonth) }}</span>
              <span class="text-nowrap">This month</span><br/>
              <span class="text-danger mr-2">{{ toString(this.stats.NewClientsLastMonth) }}</span>
              <span class="text-nowrap">Last month</span>
            </template>
          </stats-card>

        </b-col>
        <b-col xl="3" md="12">
          <stats-card data-mh
                      title="Collection rate"
                      type="gradient-info"
                      :sub-title="formatPercentage(this.stats.RepaymentRateAllTime == null ? 0 : this.stats.RepaymentRateAllTime)"
                      icon="ni ni-money-coins"
                      class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">{{ formatPercentage(this.stats.RepaymentRateYearToDate == null ? 0 : this.stats.RepaymentRateYearToDate) }}</span>
              <span class="text-nowrap">Year to Date</span><br/>
              <span class="text-success mr-2">{{ formatPercentage(this.stats.RepaymentRateThisMonth == null ? 0 : this.stats.RepaymentRateThisMonth) }}</span>
              <span class="text-nowrap">This month</span><br/>
              <span class="text-success mr-2">{{ formatPercentage(this.stats.RepaymentRateLastMonth == null ? 0 : this.stats.RepaymentRateLastMonth) }}</span>
              <span class="text-nowrap">Last month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="12">
          <stats-card data-mh
                      title="Conversion Rate"
                      type="gradient-info"
                      :sub-title="formatPercentage(this.stats.ConversionRateAllTime == null ? 0 : this.stats.ConversionRateAllTime)"
                      icon="ni ni-money-coins"
                      class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">{{ formatPercentage(this.stats.ConversionRateYearToDate == null ? 0 : this.stats.ConversionRateYearToDate) }}</span>
              <span class="text-nowrap">Year to Date</span><br/>
              <span class="text-success mr-2">{{ formatPercentage(this.stats.ConversionRateThisMonth == null ? 0 : this.stats.ConversionRateThisMonth) }}</span>
              <span class="text-nowrap">This month</span><br/>
              <span class="text-success mr-2">{{ formatPercentage(this.stats.ConversionRateLastMonth == null ? 0 : this.stats.ConversionRateLastMonth) }}</span>
              <span class="text-nowrap">Last month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="12">
          <stats-card data-mh
                      title="Avg Conversion time"
                      type="gradient-info"
                      :sub-title="formatHours(this.stats.ConversionTimeAllTime == null ? 0 : this.stats.ConversionTimeAllTime)"
                      icon="ni ni-money-coins"
                      class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">{{ formatHours(this.stats.ConversionTimeYearToDate == null ? 0 : this.stats.ConversionTimeYearToDate) }}</span>
              <span class="text-nowrap">Year to Date</span><br/>
              <span class="text-success mr-2">{{ formatHours(this.stats.ConversionTimeThisMonth == null ? 0 : this.stats.ConversionTimeThisMonth) }}</span>
              <span class="text-nowrap">This month</span><br/>
              <span class="text-success mr-2">{{ formatHours(this.stats.ConversionTimeLastMonth == null ? 0 : this.stats.ConversionTimeLastMonth) }}</span>
              <span class="text-nowrap">Last month</span>
            </template>
          </stats-card>
        </b-col>
      </b-row>
      <b-row v-if="isSupplier">
        <b-col xl="3" md="12">
          <stats-card data-mh
                      title="Purchase Total"
                      type="gradient-red"
                      :sub-title="formatCurrency(this.stats.purchaseTotalAllTime == null ? 0 : this.stats.purchaseTotalAllTime)"
                      icon="ni ni-active-40"
                      class="mb-4">

            <template slot="footer">
              <span class="text-success mr-2">{{ formatCurrency(this.stats.purchaseTotalYearToDate == null ? 0 : this.stats.purchaseTotalYearToDate) }}</span>
              <span class="text-nowrap">Year to date</span><br/>
              <span class="text-success mr-2">{{ formatCurrency(this.stats.purchaseTotalThisMonth == null ? 0 : this.stats.purchaseTotalThisMonth) }}</span>
              <span class="text-nowrap">This month</span><br/>
              <span class="text-success mr-2">{{ formatCurrency(this.stats.purchaseTotalLastMonth == null ? 0 : this.stats.purchaseTotalLastMonth) }}</span>
              <span class="text-nowrap">Last month</span>
            </template>
          </stats-card>
        </b-col>
        <b-col xl="3" md="12">
          <stats-card data-mh
                      title="Total Purchases"
                      type="gradient-green"
                      :sub-title="toString(this.stats.purchasesAllTime)"
                      icon="ni ni-chart-bar-32"
                      class="mb-4">

            <template slot="footer">
              <span class="text-danger mr-2">{{ toString(this.stats.purchasesYearToDate) }}</span>
              <span class="text-nowrap">Year to Date</span><br/>
              <span class="text-danger mr-2">{{ toString(this.stats.purchasesThisMonth) }}</span>
              <span class="text-nowrap">This Month</span><br/>
              <span class="text-danger mr-2">{{ toString(this.stats.purchasesLastMonth) }}</span>
              <span class="text-nowrap">Last month</span>
            </template>
          </stats-card>
          

        </b-col>
      </b-row>
    </base-header>
  </div>
</template>
<script>
  //State
  import { mapState, mapActions } from 'vuex'
  import config from '../config'
  // Charts
  import * as chartConfigs from '@/components/Charts/config';
  import LineChart from '@/components/Charts/LineChart';
  import BarChart from '@/components/Charts/BarChart';

  // Components
  import BaseProgress from '@/components/BaseProgress';
  import StatsCard from '@/components/Cards/StatsCard';

  import MatchHeight from 'matchheight';

  export default {
    components: {
      LineChart,
      BarChart,
      BaseProgress,
      StatsCard
    },
    computed: {
      ...mapState({
        account: state => state.account
      }),
      isSupplier() {
        return this.$store.getters['accounts/user'].supplierIds != '' ? true : false;
      },
      loggedinSupplierId() {
        return this.$store.getters['accounts/user'].supplierIds;
      }
    },
    data() {
      return {
        stats: {},
        bigLineChart: {
          allData: [
            [0, 20, 10, 30, 15, 40, 20, 60, 60]
          ],
          activeIndex: 0,
          chartData: {
            datasets: [
              {
                label: 'Performance',
                data: [0, 20, 10, 30, 15, 40, 20, 60, 60],
              }
            ],
            labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          },
          extraOptions: chartConfigs.blueChartOptions,
        },
        redBarChart: {
          chartData: {
            labels: ['Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            datasets: [{
              label: 'Sales',
              data: [25, 20, 30, 22, 17, 29]
            }]
          },
          extraOptions: chartConfigs.blueChartOptions
        }
      };
    },
    methods: {
      toString(number) {
        return number + "";
      },
      initBigChart(index) {
        let chartData = {
          datasets: [
            {
              label: 'Performance',
              data: this.bigLineChart.allData[index]
            }
          ],
          labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        };
        this.bigLineChart.chartData = chartData;
        this.bigLineChart.activeIndex = index;
      },
      formatDate(cellValue) {
        var date = new Date(cellValue);
        return ("0" + date.getDate()).slice(-2) + "/" + ("0" + (date.getMonth() + 1)).slice(-2) + "/" + date.getFullYear() + " " + ("0" + date.getHours()).slice(-2) + ":" + ("0" + date.getMinutes()).slice(-2);
      },
      formatCurrency(cellValue) {
        return parseFloat(cellValue).toLocaleString('en-ZA', { style: 'currency', currency: 'ZAR' });
      },
      formatHours(cellValue) {
        return parseFloat(cellValue).toFixed(2) + ' hrs';
      },
      formatPercentage(cellValue) {
        return parseFloat(cellValue).toFixed(2) + ' %';
      },
    },
    mounted() {
      //this.initBigChart(0);
      var http = this.axios.create({
        baseURL: config.apiUrl,
        timeout: 30000,
        headers: { 'Authorization': this.$store.getters['accounts/token'] }
      });
      if (this.isSupplier) {
        http.get('/supplier/stats/' + this.loggedinSupplierId)
          .then(response => {
            this.stats = response.data.length > 0 ? response.data[0] : {};
          });
      } else {
        http.get('/stats')
          .then(response => {
            this.stats = response.data.length > 0 ? response.data[0] : {};
          });
      }
    }
  };
</script>
<style>
.el-table .cell{
  padding-left: 0px;
  padding-right: 0px;
}
</style>
